
import moment from 'moment/moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Collapse, Card, Grid, CardActions, CardContent, Button, IconButton, Typography, CardMedia, TextField, Checkbox, FormGroup, FormControlLabel, InputAdornment } from '@mui/material';
import { claimCustomerDiscount, fetchCustomerDiscounts } from '../store/application/actions';
import { Pagination } from './common/pagination';
import { Search, CloseRounded } from '@mui/icons-material';

import Wrapper from './wrapper';
import { Loader } from './common/loader';

export const MyDiscounts = () => {

    const initialState = {
        limit: 50,
        offset: 0,
        filter: {
            q: "",
            discountState: ["available"]
        },
        sort: ["id", "ASC"],
    };
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(initialState);
    const [discountClaimedStatus, toggleDiscountClaimedStatus] = useState(false);

    const { customer, customerDiscounts: { rows, count } } = useSelector((state) => state.application);

    const paginate = (limit, offset) => {
        setFilters((prevState) => {
            return {
                ...prevState,
                limit: limit,
                offset: offset,
            };
        });
    };
    const filterChangeHandler = (event) => {
        const { id, name, value, type, checked} = event.target;

        if(type === "checkbox"){
            setFilters((prevState) => {
                return {
                ...prevState,
                filter: {
                    ...prevState["filter"],
                    [id]: checked ? [...prevState["filter"][id], name] : prevState["filter"][id].filter(state => state !== name) 
                }
                };
            });
        }
        else{
            setFilters((prevState) => {
                return {
                ...prevState,
                filter: {
                    ...prevState["filter"],
                    [name]: value,
                },
                };
            });
        }
    };

    const filterCustomerDiscounts = () => {
        dispatch(fetchCustomerDiscounts({ customerId: customer.id, ...filters }));
    }

    const claimDiscount = async(e) => {
        try{
            await dispatch(claimCustomerDiscount(e.target.id));
            dispatch(fetchCustomerDiscounts({customerId: customer.id}));
            toggleDiscountClaimedStatus(true);
        }
        catch(error){
            console.log(error);
        }
    }

    return (
        <Wrapper currentLabel="Discounts">
            { rows?.length > 0 && <>
                <Box p={2}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={3} m={1}>
                            <TextField 
                                label="Search your query..." 
                                fullWidth 
                                color="warning"  
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                    ),
                                }}
                                value={filters.filter.q}
                                onChange={filterChangeHandler}
                                id="q"
                                name="q"

                            /> 
                        </Grid>
                        <Grid item xs={12} md={3} m={1}>
                            <FormGroup sx={{display: "inline"}}>
                                <FormControlLabel control={<Checkbox id='discountState' name='available' checked={filters.filter.discountState.includes("available") } onChange={filterChangeHandler} color='warning'/> } label="Available" />
                                <FormControlLabel control={<Checkbox id='discountState' name='claimed'  checked={filters.filter.discountState.includes("claimed")}    onChange={filterChangeHandler} color='warning'/>}  label="Claimed" />
                                <FormControlLabel control={<Checkbox id='discountState' name='expired'  checked={filters.filter.discountState.includes("expired")}    onChange={filterChangeHandler} color='warning'/>}  label="Expired" />
                            </FormGroup> 
                        </Grid>
                        <Grid item xs={12} md={1} m={1}>
                            <Button variant="contained" color="warning" fullWidth onClick={filterCustomerDiscounts}>Filter</Button>
                        </Grid>
                    </Grid>            
                </Box>

                <Box p={2}>
                    <Grid container>
                        {rows.map((discountCardObj) => {
                            return (
                                <Grid item xs={12} sm={6} md={2.5} m={2}>
                                    <Card>
                                        <br></br>
                                        <CardMedia>
                                            <img style={{ height: '240px', display: "block", margin: "0px auto" }} src="https://b.zmtcdn.com/data/pictures/chains/5/19231655/dd6e13e1f9c87b705983b59cfbcc605b.jpg?output-format=webp&fit=around|300:273&crop=300:273;*,*" />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant={'h6'} sx={{ fontWeight: "bold", color: "#ff9800" }}>
                                                {discountCardObj.percentage}% OFF
                                            </Typography>
                                            <Typography sx={{ fontSize: 16, fontWeight: "bold" }} gutterBottom>
                                                {discountCardObj?.customerFeedback?.merchantBusiness.name}
                                            </Typography>
                                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }} variant="body2" gutterBottom>
                                                Address: {discountCardObj?.customerFeedback?.merchantBusiness.address}
                                            </Typography>
                                            <Typography sx={{ mt: 1, fontSize: 16, color: "#e65100" }} gutterBottom>
                                                {discountCardObj.claimed ? `Claimed: ${moment(discountCardObj.claimedDate).format("DD-MM-YYYY  HH:mm")}` : `Expiry: ${moment(discountCardObj.expiryDate).format("DD-MM-YYYY")}`}
                                            </Typography>
                                            <Typography variant="body2" align='justify' sx={{fontSize: 12, fontWeight: 'bold', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2}}>
                                                {discountCardObj?.customerFeedback?.rawText}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button 
                                                id={discountCardObj.id}
                                                sx={{ fontSize: "14px", fontWeight: "bold" }} 
                                                color="warning" 
                                                size="small" 
                                                variant='contained' 
                                                fullWidth 
                                                onClick={claimDiscount}
                                                disabled={discountCardObj.claimed || new Date(discountCardObj.expiryDate) < new Date()}  
                                            >
                                                Claim
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </Box>
                <Pagination
                    limit={filters.limit}
                    offset={filters.offset}
                    count={count}
                    updateFilters={paginate}
                />

                <Collapse in={discountClaimedStatus} sx={{ position: "fixed", bottom: "0%", width:"100%", opacity: 1 }}>
                    <Alert
                        color={"warning"}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    toggleDiscountClaimedStatus(!discountClaimedStatus);
                                }}
                            >
                                <CloseRounded fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2, fontFamily: "monospace", fontSize: "large", fontWeight: "bold", textAlign: "center" }}
                    >
                        Discount Claimed Successfully!
                    </Alert>
                </Collapse>
            </>
            }
        </Wrapper>
    )
}
