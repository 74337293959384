
export const reducers = {
  setMerchant(state, action) {
    const { merchant } = action.payload;
    state.merchant = merchant;
  },
  setFeedback(state, action) {
    const { feedback } = action.payload;
    state.feedback = feedback;
  },
  setCustomer(state, action) {
    const { customer } = action.payload;
    state.customer = customer;
  },
  setCustomerDiscounts(state, action) {
    const { customerDiscounts } = action.payload;
    state.customerDiscounts = customerDiscounts;
  }
};
