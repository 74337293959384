
import { Routes, Route, BrowserRouter } from "react-router-dom";

import SignIn  from "./components/login";
import { HomePage } from "./components/home";
import { MyDiscounts } from './components/myDiscounts';
import { ThemeProvider, createTheme } from "@mui/material";


function App() {
  
  const theme = createTheme({
    palette: {
      mode: "dark"
    }
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/"  element={<SignIn />} />
            <Route path="/home" element={<HomePage /> } />
            <Route path="/my-discounts" element={<MyDiscounts />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;