import axios from "axios";
import { applicationActions } from "./slice";

export const fetchMerchantDetails = ({ businessId }) => {
    return async (dispatch) => {
        try {
            const { data: { data } } = await axios.get(`https://api.popscom.life/merchant/business/${businessId}`);
            dispatch(
                applicationActions.setMerchant({
                    merchant: data
                })
            );
        } catch (error) {
            throw error;
        }
    };
};

export const postCustomerFeedback = ({ audio, language }) => {
    return async (dispatch) => {
        try {
            const form = new FormData();
            form.append("audio", audio);
            form.append("language", language);
            
            const { data: { data } } = await axios.post(`https://api.popscom.life/customer/feedback`, form, {
                "Content-Type": "multipart/form-data",
            });

            dispatch(applicationActions.setFeedback({feedback: data})
            );
        } catch (error) {
            throw error;
        }
    };
};

export const validateUser = ({ user }) => {
    return async (dispatch) => {
        try{
            const { data} = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })

            const payload = {
                firstName: data.given_name,
                lastName: data.family_name,
                email: data.email
            }

            const {data: {data: customer }} = await axios.post('https://api.popscom.life/customer', payload);
            dispatch(applicationActions.setCustomer({customer: customer[0]}));
        }
        catch(error){
            throw error;
        }
    }
}

export const fetchCustomer = ({customerId}) => {
    return async (dispatch) => {
        try{
            const {data: {data: customer }} = await axios.get(`https://api.popscom.life/customer/${customerId}`);
            dispatch(applicationActions.setCustomer({customer: customer}));
        }
        catch(error){
            throw error;
        }
    }
}

export const createCustomerDiscount = ({ feedbackId, customerId, merchantBusinessId}) => {
    return async (dispatch) => {
        try {
            
            await axios.post(`https://api.popscom.life/discount`, {
                "merchantBusinessId": merchantBusinessId,
                "customerId": customerId,
                "feedbackId": feedbackId
            });

        } catch (error) {
            throw error;
        }
    };
};

export const fetchCustomerDiscounts = (queryObj) => {
    return async (dispatch) => {
        try{
            
            const { data: { data: { count, rows } } } = await axios.get(`https://api.popscom.life/discount`, { params: queryObj});
            dispatch(
                applicationActions.setCustomerDiscounts({
                    customerDiscounts: { count, rows}
                })
            );
        }
        catch(error){
            throw error;
        }
    }
}

export const claimCustomerDiscount = (discountId) => {
    return async (dispatch) => {
        try{
            const response = await axios.put(`https://api.popscom.life/discount/${discountId}`);
            return response;
        }
        catch(error){
            throw error;
        }
    }
}

export const updateCustomer = (customerId, payload) => {
    return async (dispatch) => {
        try{
            await axios.put(`https://api.popscom.life/customer/${customerId}`, payload);
            dispatch(fetchCustomer({ customerId}));
            // dispatch(applicationActions.setCustomer({customer: customer}));
        }
        catch(error){
            throw error;
        }
    }
}
