
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBd6tMCtAO8mz7CUmbYAlkC2_5aPKNnkeE",
  authDomain: "lyrical-compass-394206.firebaseapp.com",
  projectId: "lyrical-compass-394206",
  storageBucket: "lyrical-compass-394206.appspot.com",
  messagingSenderId: "367785368504",
  appId: "1:367785368504:web:5dd0cf28cb6266636f0d4d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = true;
export default app;

// export default firebaseConfig;