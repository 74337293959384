
import React, { useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { fetchCustomerDiscounts, validateUser } from '../store/application/actions';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://popscom.life/">
                Pops
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

const SignIn = () => {

    const TEST_CUSTOMER="b1531f00-7cba-11ee-bc3e-2bed02aa60f1"
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { customer, customerDiscounts } = useSelector((state) => state.application);

    const [ user, setUser ] = useState(null);
    const [ phone, setPhone] = useState('+91');
    const [ otp, setOtp] = useState('');
    const [ displaySection, setDisplaySection] = useState("default");

    const generateRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        }
      }, auth);
    }

    const handleSend = (event) => {
      event.preventDefault();
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setDisplaySection("otp")
        }).catch((error) => {
          console.log(error);
        });
    }
  
    const verifyOtp = (event) => {
      event.preventDefault();
      const confirmationResult = window.confirmationResult;
      
      confirmationResult.confirm(otp).then((result) => {
        let user = result.user;

      }).catch((error) => {
        alert('User couldn\'t sign in (bad verification code?)');
      });
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (user) {
            dispatch(validateUser({ user: user}));
        }
    }, [ user ]);

    useEffect(()=> {
        if(customer?.email){
            dispatch(fetchCustomerDiscounts({ customerId: TEST_CUSTOMER}));
        }
    }, [customer])

    useEffect(()=>{
        if(customerDiscounts?.count >= 0){
            navigate('/home');
        }
    }, [customerDiscounts])


    return (
            <Container component="main" maxWidth="xs">

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        paddingTop: 10,
                        paddingBottom: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                    }}
                >
                    <br></br>
                    <Avatar sx={{ m: 1, bgcolor: '#ff9800' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {displaySection == "default" && <Box component="form" onSubmit={handleSend} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="mobile"
                            label="Enter Mobile Number"
                            name="mobile"
                            autoComplete="mobile"
                            autoFocus
                            value={phone}
                            color="warning"
                            onChange={(e)=>setPhone(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="warning"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                        <div id="recaptcha"></div>

                        <Typography variant='h6' align='center'>OR</Typography>

                        <Button
                            fullWidth
                            variant="contained"
                            color="error"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={login}
                        >
                            Sign in with Google
                        </Button>
                    </Box>
                    }

                    {displaySection == "otp" && <Box component="form" onSubmit={verifyOtp} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="otp"
                            label="Enter 6 Digit Verification Code"
                            name="otp"
                            autoComplete="otp"
                            autoFocus
                            value={otp}
                            onChange={(e)=>setOtp(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Validate OTP
                        </Button>
                        <div id="recaptcha"></div>
                    </Box>
                    }
                    <br></br>
                </Box>
                <Copyright sx={{ mt: 4, mb: 4 }} />
            </Container>
    );
}

export default SignIn;



