import { Box, Button, CssBaseline, Container, Typography, Grid, Divider, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { updateCustomer } from '../store/application/actions';

import Wrapper from './wrapper';

export const HomePage = () => {

    const [claimedDiscounts, setClaimedDiscounts] = useState(0);
    const [feedbackCount, setFeedbackCount] = useState(0);

    const { customer, customerDiscounts: { rows } } = useSelector((state) => state.application);
    const dispatch = useDispatch();

    useEffect(() => {
        if (rows && rows.length > 0) {
            let claimed = 0, feedbackObj = {};

            rows.forEach(discountObj => {
                if (discountObj.claimed) {
                    claimed += 1;
                }
                feedbackObj[discountObj.customerFeedbackId] = 1;
            })
            setFeedbackCount(Object.keys(feedbackObj).length);
            setClaimedDiscounts(claimed);
        }
    }, [rows]);

    const formik = useFormik({
        initialValues: {
            id: customer.id ?? "",
            firstName: customer.firstName ?? "",
            lastName: customer.lastName ?? "",
            email: customer.email ?? "",
            mobile: customer.phone ?? ""
        },
        validateOnChange: true,
        enableReinitialize: true,
        validate: (values)=>{
            const errors = {};
            Object.keys(values).forEach(key=> {
                if(values[key] == ""){
                    errors[key] = true;
                }
            })
            return errors;
        },
        onSubmit: (values) => {
            const payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.mobile
            }
            dispatch(updateCustomer(values.id, payload));
        }
    })

    return (
        <Wrapper currentLabel="Home">
            <Container component="main" maxWidth={"md"}>
                <CssBaseline />
                <Box mt={8}>

                    <Typography component="h4" variant="h4" color={"#ff9800"} fontFamily={"monospace"}>
                        Overview 
                    </Typography>
                    <br></br>

                    <Typography component="h5" variant="h5" p={2} fontFamily={"monospace"}>
                        Feedbacks Given: NA
                    </Typography>
                    <Typography component="h5" variant="h5" p={2} fontFamily={"monospace"}>
                        Sentiment Score: NA
                    </Typography>
                    <Typography component="h5" variant="h5" p={2} fontFamily={"monospace"}>
                        Discounts Claimed: NA
                    </Typography>
                    
                    
                    <br></br>
                    <Divider sx={{borderColor: "#ff9800"}} variant='middle'></Divider>
                    <br></br>

                    <Grid container>
                        <Grid item xs={12} md={6} p={2}>
                            <TextField 
                                id={"firstName"} 
                                name={"firstName"} 
                                label={"First Name *"} 
                                color="warning" 
                                fullWidth  
                                value={formik.values.firstName} 
                                onChange={formik.handleChange} 
                                error={formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} p={2}>
                            <TextField 
                                id={"lastName"} 
                                name={"lastName"} 
                                label={"Last Name *"} 
                                color="warning" 
                                fullWidth 
                                value={formik.values.lastName} 
                                onChange={formik.handleChange}
                                error={formik.errors.lastName} 
                            />
                        </Grid>
                        <Grid item xs={12} md={6} p={2}>
                            <TextField 
                                id={"email"} 
                                name={"email"} 
                                label={"Email *"} 
                                color="warning" 
                                fullWidth 
                                value={formik.values.email} 
                                onChange={formik.handleChange} 
                                error={formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} p={2}>
                            <TextField 
                                id={"mobile"} 
                                name={"mobile"} 
                                label={"Mobile Number *"} 
                                color="warning" 
                                fullWidth 
                                value={formik.values.mobile} 
                                onChange={formik.handleChange}
                                error={formik.errors.mobile} 
                            />
                        </Grid>
                        <Grid item xs={12} p={2}>
                            <Button 
                                color="warning" 
                                variant="contained" 
                                fullWidth 
                                onClick={formik.handleSubmit}>
                                    Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Wrapper>
    )
}